import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Json: { input: any; output: any; }
};

export enum GalxeQuestType {
  GalaxeQuest = 'galaxeQuest',
  GalaxeXCryfiQuest = 'galaxeXCryfiQuest',
  GalaxeXDysonFinance = 'galaxeXDysonFinance',
  GalaxeXOatAdopter = 'galaxeXOatAdopter',
  GalaxeXOatDeveloper = 'galaxeXOatDeveloper',
  GalaxeXOatTestnet = 'galaxeXOatTestnet'
}

export type IntractEligibility = {
  __typename?: 'IntractEligibility';
  awarded?: Maybe<Scalars['Boolean']['output']>;
  eligible?: Maybe<Scalars['Boolean']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ClaimGalxePoints?: Maybe<Scalars['Json']['output']>;
  GMPContractDeployQuest?: Maybe<Scalars['Json']['output']>;
  GMPSubmitMessageQuest?: Maybe<Scalars['Json']['output']>;
  blackListDomain?: Maybe<Scalars['Json']['output']>;
  claimIntractPoint?: Maybe<IntractEligibility>;
  claimQuaerePoints?: Maybe<Scalars['Json']['output']>;
  claimZealyQuestPoints?: Maybe<Scalars['Json']['output']>;
  connectWallet?: Maybe<Scalars['Json']['output']>;
  connectedEvmWalletQuest?: Maybe<Scalars['Json']['output']>;
  connectedWalletOnWatchQuest?: Maybe<Scalars['Json']['output']>;
  createdAPIKeyQuest?: Maybe<Scalars['Json']['output']>;
  discntDis?: Maybe<Scalars['Json']['output']>;
  endVotingSession?: Maybe<Scalars['Json']['output']>;
  runBlackListScript?: Maybe<Scalars['Json']['output']>;
  setFP?: Maybe<Scalars['Json']['output']>;
  shortListViews?: Maybe<Scalars['Json']['output']>;
  startVotingSession?: Maybe<Scalars['Json']['output']>;
  submittedFeedbackQuest?: Maybe<Scalars['Json']['output']>;
  submittedViewForGameQuest?: Maybe<Scalars['Json']['output']>;
  switchSubstrateWallet?: Maybe<Scalars['Json']['output']>;
  updateDomainStatus?: Maybe<Scalars['Json']['output']>;
  updateVotingSession?: Maybe<Scalars['Json']['output']>;
  verifyDmailSendEmailQuest?: Maybe<Scalars['Json']['output']>;
  verifyProofOfHumanity?: Maybe<Scalars['Json']['output']>;
  vote?: Maybe<Scalars['Json']['output']>;
  whiteListDomain?: Maybe<Scalars['Json']['output']>;
};


export type MutationClaimGalxePointsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  evmWalletAddress: Scalars['String']['input'];
  questType: GalxeQuestType;
};


export type MutationGmpContractDeployQuestArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  connectedChain: ConnectedChainType;
  contractAddress: Scalars['String']['input'];
  evmWalletAddress: Scalars['String']['input'];
};


export type MutationGmpSubmitMessageQuestArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  connectedChain: ConnectedChainType;
  evmWalletAddress: Scalars['String']['input'];
  transactionHash: Scalars['String']['input'];
};


export type MutationBlackListDomainArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  emailDomain: Array<Scalars['String']['input']>;
};


export type MutationClaimIntractPointArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  evmAddress: Scalars['String']['input'];
};


export type MutationClaimQuaerePointsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationClaimZealyQuestPointsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationConnectWalletArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  walletAddress: Scalars['String']['input'];
};


export type MutationConnectedEvmWalletQuestArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  walletAddress: Scalars['String']['input'];
};


export type MutationConnectedWalletOnWatchQuestArgs = {
  wallet: Scalars['String']['input'];
  walletName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatedApiKeyQuestArgs = {
  wallet: Scalars['String']['input'];
};


export type MutationDiscntDisArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};


export type MutationEndVotingSessionArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRunBlackListScriptArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetFpArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  vId: Scalars['String']['input'];
};


export type MutationShortListViewsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  view: Scalars['Json']['input'];
};


export type MutationStartVotingSessionArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  data: VotingSessionInputType;
};


export type MutationSubmittedFeedbackQuestArgs = {
  email: Scalars['String']['input'];
};


export type MutationSubmittedViewForGameQuestArgs = {
  wallet: Scalars['String']['input'];
};


export type MutationSwitchSubstrateWalletArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  newWalletAddress: Scalars['String']['input'];
};


export type MutationUpdateDomainStatusArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  emailDomain: Scalars['String']['input'];
  isBlackListed?: InputMaybe<Scalars['Boolean']['input']>;
  isWhiteListed?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateVotingSessionArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationVerifyDmailSendEmailQuestArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationVerifyProofOfHumanityArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  evmWalletAddress: Scalars['String']['input'];
};


export type MutationVoteArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  data: VoteInputArgs;
};


export type MutationWhiteListDomainArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  emailDomain: Array<Scalars['String']['input']>;
};

export type Points = {
  __typename?: 'Points';
  id: Scalars['Int']['output'];
  subType: DeveloperQuestSubType;
  totalPoints: Scalars['Int']['output'];
  type: PointsType;
  user: User;
  userId: Scalars['Int']['output'];
};

export enum PointsType {
  Bonus = 'bonus',
  CommunityQuest = 'communityQuest',
  DeveloperQuest = 'developerQuest',
  EarlyBonus = 'earlyBonus',
  PartnerQuest = 'partnerQuest',
  Referral = 'referral',
  ViewShortListed = 'viewShortListed',
  VotingSessionParticipated = 'votingSessionParticipated',
  WinnerAsDesigner = 'winnerAsDesigner',
  WinnerAsVoter = 'winnerAsVoter'
}

export type Query = {
  __typename?: 'Query';
  SessionWinnerView?: Maybe<Views>;
  UserPoints?: Maybe<UserPointsResult>;
  activeVotingSession?: Maybe<VotingSession>;
  activeVotingSessionViews: Array<Views>;
  completedDevQuestCount?: Maybe<Array<Maybe<CompletedDevQuestCountType>>>;
  domainList?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  earlyBonusPoints?: Maybe<Scalars['Int']['output']>;
  fetchGalaxyQuestPoints?: Maybe<CheckGalaxyQuestCompletedType>;
  getUser?: Maybe<User>;
  leaderBoard?: Maybe<Array<Maybe<LeaderBoardType>>>;
  shortListedViews: Array<Maybe<SearchResult>>;
  validatorLeaderBoard?: Maybe<Array<Maybe<ValidatorLeaderBoardType>>>;
  verifyCredential?: Maybe<Scalars['Json']['output']>;
  viewData?: Maybe<Scalars['Json']['output']>;
  viewSql?: Maybe<Scalars['Json']['output']>;
  votingSessionList?: Maybe<Array<Maybe<VotingSession>>>;
  votingSessionPoints?: Maybe<VotingSessionPointsResult>;
  watchViewList?: Maybe<Scalars['Json']['output']>;
};


export type QuerySessionWinnerViewArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['Int']['input'];
};


export type QueryUserPointsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryActiveVotingSessionViewsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompletedDevQuestCountArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDomainListArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  isBlackListed?: InputMaybe<Scalars['Boolean']['input']>;
  isWhiteListed?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEarlyBonusPointsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchGalaxyQuestPointsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  evmWalletAddress: Scalars['String']['input'];
  questType: GalxeQuestType;
};


export type QueryGetUserArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryShortListedViewsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVerifyCredentialArgs = {
  Dmailusercode?: InputMaybe<Scalars['String']['input']>;
  accessToken?: InputMaybe<Scalars['String']['input']>;
  referredCode?: InputMaybe<Scalars['String']['input']>;
  referrerSite?: InputMaybe<Scalars['String']['input']>;
};


export type QueryViewDataArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  hashId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryViewSqlArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  hashId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVotingSessionListArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVotingSessionPointsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWatchViewListArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type QuestPointsSubType = {
  __typename?: 'QuestPointsSubType';
  points?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<DeveloperQuestSubType>;
};

export type SearchResult = Views | WatchView;

export type Subscription = {
  __typename?: 'Subscription';
  votingSessionEnded?: Maybe<VotingSessionSubscriptionType>;
  votingSessionPointsDistributed?: Maybe<VotingSessionSubscriptionType>;
  votingSessionStarted?: Maybe<VotingSessionSubscriptionType>;
  votingSessionUpdated?: Maybe<VotingSessionSubscriptionType>;
};

export type User = {
  __typename?: 'User';
  POHScore: Scalars['Int']['output'];
  VotingSessionWon: Array<VotingSession>;
  _count?: Maybe<UserCounts>;
  connectedDiscordServer: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isBlackListed: Scalars['Boolean']['output'];
  isVerifiedByPOH: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['BigInt']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referredBy?: Maybe<Scalars['Int']['output']>;
  referredToUsers: Array<User>;
  userType: UserTypeEnum;
  votes: Array<Votes>;
  votingSessionParticipated: Array<UserVotingSession>;
  walletAddress?: Maybe<Scalars['String']['output']>;
};

export type UserCounts = {
  __typename?: 'UserCounts';
  VotingSessionWon: Scalars['Int']['output'];
  ownedViews: Scalars['Int']['output'];
  referredToUsers: Scalars['Int']['output'];
  votingSessionParticipated: Scalars['Int']['output'];
};

export type UserPointsResult = {
  __typename?: 'UserPointsResult';
  communityQuest?: Maybe<Scalars['Int']['output']>;
  developerQuest?: Maybe<Scalars['Int']['output']>;
  earlyBonus?: Maybe<Scalars['Int']['output']>;
  expectedReferral?: Maybe<Scalars['Int']['output']>;
  partnerQuest?: Maybe<Scalars['Int']['output']>;
  questPointsSubType?: Maybe<Array<Maybe<QuestPointsSubType>>>;
  referral?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  viewShortListed?: Maybe<Scalars['Int']['output']>;
  votingSessionParticipated?: Maybe<Scalars['Int']['output']>;
  winnerAsDesigner?: Maybe<Scalars['Int']['output']>;
  winnerAsVoter?: Maybe<Scalars['Int']['output']>;
};

export enum UserTypeEnum {
  DataDesigner = 'dataDesigner',
  Voter = 'voter'
}

export type UserVotingSession = {
  __typename?: 'UserVotingSession';
  user: User;
  userId: Scalars['Int']['output'];
  votingSessionId: Scalars['Int']['output'];
  votingSessions: VotingSession;
};

export type Views = {
  __typename?: 'Views';
  created_at: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hashId: Scalars['ID']['output'];
  isBlocked: Scalars['Boolean']['output'];
  isWinner: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  owner: User;
  ownerId: Scalars['Int']['output'];
  tags?: Maybe<Scalars['Json']['output']>;
  useCases: Array<Scalars['String']['output']>;
  votes: Array<Votes>;
  votingSessionParticipated?: Maybe<VotingSession>;
  votingSessionParticipatedId?: Maybe<Scalars['Int']['output']>;
};

export type VoteInputArgs = {
  VotingSessionId: Scalars['Int']['input'];
  viewHash: Scalars['String']['input'];
};

export type Votes = {
  __typename?: 'Votes';
  id: Scalars['Int']['output'];
  view: Views;
  viewHash: Scalars['String']['output'];
  voter: User;
  voterId: Scalars['Int']['output'];
  votingSession: VotingSession;
  votingSessionId: Scalars['Int']['output'];
};

export type VotingSession = {
  __typename?: 'VotingSession';
  _count?: Maybe<_Count>;
  created_at: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  winnerView?: Maybe<WinnerViewType>;
};

export type VotingSessionInputType = {
  endDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  shortlistedViewsHashes: Array<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type VotingSessionPointsResult = {
  __typename?: 'VotingSessionPointsResult';
  sessionPoints?: Maybe<Array<Maybe<SessionPointsType>>>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  winsAsDataDesigner?: Maybe<Scalars['Int']['output']>;
  winsAsVoter?: Maybe<Scalars['Int']['output']>;
};

export enum VotingSessionStatus {
  Completed = 'completed',
  Paused = 'paused',
  Started = 'started'
}

export type _Count = {
  __typename?: '_count';
  views: Scalars['Int']['output'];
  votes: Scalars['Int']['output'];
};

export type CheckGalaxyQuestCompletedType = {
  __typename?: 'checkGalaxyQuestCompletedType';
  canClaimMore?: Maybe<Scalars['Boolean']['output']>;
  claimedPointsFromGalaxe?: Maybe<Scalars['Int']['output']>;
  completed?: Maybe<Scalars['Boolean']['output']>;
};

export type CompletedDevQuestCountType = {
  __typename?: 'completedDevQuestCountType';
  count?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<DeveloperQuestSubType>;
};

export enum ConnectedChainType {
  Sepolia = 'sepolia',
  Shibuya = 'shibuya'
}

export enum DeveloperQuestSubType {
  Bonus = 'bonus',
  CommunityQuest = 'communityQuest',
  ConnectDiscordOrTwitter = 'connectDiscordOrTwitter',
  ConnectEvmWalletOnTestnet = 'connectEVMWalletOnTestnet',
  ConnectedWalletOnWatch = 'connectedWalletOnWatch',
  CreatedApiKey = 'createdAPIKey',
  CreatedView = 'createdView',
  DMail = 'dMail',
  DeployedSmartContractUsingGmp = 'deployedSmartContractUsingGMP',
  DeployedSmartContractUsingGmpOnSepolia = 'deployedSmartContractUsingGMPOnSepolia',
  DeployedSmartContractUsingGmpOnShibuya = 'deployedSmartContractUsingGMPOnShibuya',
  DeveloperQuest = 'developerQuest',
  EarlyBonus = 'earlyBonus',
  GalaxeQuest = 'galaxeQuest',
  GalaxeXCryfiQuest = 'galaxeXCryfiQuest',
  GalaxeXDysonFinance = 'galaxeXDysonFinance',
  GalaxeXOatAdopter = 'galaxeXOatAdopter',
  GalaxeXOatDeveloper = 'galaxeXOatDeveloper',
  GalaxeXOatTestnet = 'galaxeXOatTestnet',
  Intract = 'intract',
  IntractXMetaStreet = 'intractXMetaStreet',
  ListedSmartContract = 'listedSmartContract',
  OwnerViewQueriedByUser = 'ownerViewQueriedByUser',
  PartnerQuest = 'partnerQuest',
  ProofOfHumanity = 'proofOfHumanity',
  Quaere = 'quaere',
  QueryView = 'queryView',
  Referral = 'referral',
  SentEmailOnDmail = 'sentEmailOnDmail',
  SentMessageUsingGmpGateway = 'sentMessageUsingGMPGateway',
  SentMessageUsingGmpGatewayOnSepolia = 'sentMessageUsingGMPGatewayOnSepolia',
  SentMessageUsingGmpGatewayOnShibuya = 'sentMessageUsingGMPGatewayOnShibuya',
  SignInWithEmail = 'signInWithEmail',
  SignInWithWalletOnTestnet = 'signInWithWalletOnTestnet',
  SponsorView = 'sponsorView',
  SponsorViewQueriedByUser = 'sponsorViewQueriedByUser',
  SubmittedFeedback = 'submittedFeedback',
  SubmittedViewForGame = 'submittedViewForGame',
  Talisman = 'talisman',
  ViewShortListed = 'viewShortListed',
  VotingSessionParticipated = 'votingSessionParticipated',
  WinnerAsDesigner = 'winnerAsDesigner',
  WinnerAsVoter = 'winnerAsVoter',
  Zealy = 'zealy'
}

export type LeaderBoardType = {
  __typename?: 'leaderBoardType';
  name?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['BigInt']['output']>;
  rank?: Maybe<Scalars['BigInt']['output']>;
};

export type SessionPointsType = {
  __typename?: 'sessionPointsType';
  points?: Maybe<Scalars['Int']['output']>;
  sessionId?: Maybe<Scalars['Int']['output']>;
  wonAsDataDesigner?: Maybe<Scalars['Boolean']['output']>;
  wonAsVoter?: Maybe<Scalars['Boolean']['output']>;
};

export type ShortListViewsInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  hashId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tags?: InputMaybe<Scalars['Json']['input']>;
  walletAddress: Scalars['String']['input'];
};

export type ValidatorLeaderBoardType = {
  __typename?: 'validatorLeaderBoardType';
  address?: Maybe<Scalars['String']['output']>;
  commission?: Maybe<Scalars['String']['output']>;
  discovered?: Maybe<Scalars['String']['output']>;
  faults?: Maybe<Scalars['String']['output']>;
  inValidReason?: Maybe<Scalars['String']['output']>;
  inclusion?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['String']['output']>;
  rewards?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type VotingSessionSubscriptionType = {
  __typename?: 'votingSessionSubscriptionType';
  votingSession?: Maybe<VotingSession>;
};

export type WatchView = {
  __typename?: 'watchView';
  data?: Maybe<Scalars['Json']['output']>;
};

export type WinnerViewType = {
  __typename?: 'winnerViewType';
  hashId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ShortListViewsMutationVariables = Exact<{
  view: Scalars['Json']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ShortListViewsMutation = { __typename?: 'Mutation', shortListViews?: any | null };

export type StartVotingSessionMutationVariables = Exact<{
  data: VotingSessionInputType;
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type StartVotingSessionMutation = { __typename?: 'Mutation', startVotingSession?: any | null };

export type VoteMutationVariables = Exact<{
  data: VoteInputArgs;
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type VoteMutation = { __typename?: 'Mutation', vote?: any | null };

export type EndVotingSessionMutationVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type EndVotingSessionMutation = { __typename?: 'Mutation', endVotingSession?: any | null };

export type UpdateVotingSessionMutationVariables = Exact<{
  updateVotingSessionId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateVotingSessionMutation = { __typename?: 'Mutation', updateVotingSession?: any | null };

export type ConnectWalletMutationVariables = Exact<{
  walletAddress: Scalars['String']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ConnectWalletMutation = { __typename?: 'Mutation', connectWallet?: any | null };

export type SwitchSubstrateWalletMutationVariables = Exact<{
  newWalletAddress: Scalars['String']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type SwitchSubstrateWalletMutation = { __typename?: 'Mutation', switchSubstrateWallet?: any | null };

export type ConnectedEvmWalletQuestMutationVariables = Exact<{
  walletAddress: Scalars['String']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ConnectedEvmWalletQuestMutation = { __typename?: 'Mutation', connectedEvmWalletQuest?: any | null };

export type GmpSubmitMessageQuestMutationVariables = Exact<{
  evmWalletAddress: Scalars['String']['input'];
  transactionHash: Scalars['String']['input'];
  connectedChain: ConnectedChainType;
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GmpSubmitMessageQuestMutation = { __typename?: 'Mutation', GMPSubmitMessageQuest?: any | null };

export type GmpContractDeployQuestMutationVariables = Exact<{
  evmWalletAddress: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
  connectedChain: ConnectedChainType;
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GmpContractDeployQuestMutation = { __typename?: 'Mutation', GMPContractDeployQuest?: any | null };

export type SubmittedFeedbackQuestMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SubmittedFeedbackQuestMutation = { __typename?: 'Mutation', submittedFeedbackQuest?: any | null };

export type ClaimGalxePointsMutationVariables = Exact<{
  evmWalletAddress: Scalars['String']['input'];
  questType: GalxeQuestType;
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ClaimGalxePointsMutation = { __typename?: 'Mutation', ClaimGalxePoints?: any | null };

export type SetFpMutationVariables = Exact<{
  vId: Scalars['String']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type SetFpMutation = { __typename?: 'Mutation', setFP?: any | null };

export type ClaimIntractPointMutationVariables = Exact<{
  evmAddress: Scalars['String']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ClaimIntractPointMutation = { __typename?: 'Mutation', claimIntractPoint?: { __typename?: 'IntractEligibility', awarded?: boolean | null, eligible?: boolean | null } | null };

export type VerifyProofOfHumanityMutationVariables = Exact<{
  evmWalletAddress: Scalars['String']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type VerifyProofOfHumanityMutation = { __typename?: 'Mutation', verifyProofOfHumanity?: any | null };

export type VerifyDmailSendEmailQuestMutationVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type VerifyDmailSendEmailQuestMutation = { __typename?: 'Mutation', verifyDmailSendEmailQuest?: any | null };

export type ClaimQuaerePointsMutationVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ClaimQuaerePointsMutation = { __typename?: 'Mutation', claimQuaerePoints?: any | null };

export type ClaimZealyQuestPointsMutationVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ClaimZealyQuestPointsMutation = { __typename?: 'Mutation', claimZealyQuestPoints?: any | null };

export type VerifyCredentialQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
  referredCode?: InputMaybe<Scalars['String']['input']>;
  referrerSite?: InputMaybe<Scalars['String']['input']>;
  Dmailusercode?: InputMaybe<Scalars['String']['input']>;
}>;


export type VerifyCredentialQuery = { __typename?: 'Query', verifyCredential?: any | null };

export type ActiveVotingSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveVotingSessionQuery = { __typename?: 'Query', activeVotingSession?: { __typename?: 'VotingSession', id: number, name?: string | null, startDate: any, endDate: any, created_at: any, _count?: { __typename?: '_count', views: number, votes: number } | null } | null };

export type ActiveVotingSessionViewsQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ActiveVotingSessionViewsQuery = { __typename?: 'Query', activeVotingSessionViews: Array<{ __typename?: 'Views', hashId: string, isWinner: boolean, name: string, tags?: any | null, created_at: any, description?: string | null, useCases: Array<string>, owner: { __typename?: 'User', walletAddress?: string | null } }> };

export type VotingSessionListQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type VotingSessionListQuery = { __typename?: 'Query', votingSessionList?: Array<{ __typename?: 'VotingSession', id: number, name?: string | null, created_at: any, winnerView?: { __typename?: 'winnerViewType', name: string, hashId: string } | null, _count?: { __typename?: '_count', votes: number, views: number } | null } | null> | null };

export type SessionWinnerViewQueryVariables = Exact<{
  sessionId: Scalars['Int']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type SessionWinnerViewQuery = { __typename?: 'Query', SessionWinnerView?: { __typename?: 'Views', hashId: string, description?: string | null, name: string, tags?: any | null, created_at: any, owner: { __typename?: 'User', walletAddress?: string | null } } | null };

export type WatchViewListQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type WatchViewListQuery = { __typename?: 'Query', watchViewList?: any | null };

export type ShortListedViewsQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ShortListedViewsQuery = { __typename?: 'Query', shortListedViews: Array<{ __typename?: 'Views', description?: string | null, hashId: string, isBlocked: boolean, name: string, tags?: any | null, owner: { __typename?: 'User', walletAddress?: string | null } } | { __typename?: 'watchView', data?: any | null } | null> };

export type GetUserQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id: number, name?: string | null, created_at: any, email?: string | null, walletAddress?: string | null, referralCode?: string | null, connectedDiscordServer: boolean, isVerifiedByPOH: boolean, POHScore: number, rank?: any | null, _count?: { __typename?: 'UserCounts', referredToUsers: number } | null, votingSessionParticipated: Array<{ __typename?: 'UserVotingSession', votingSessionId: number }> } | null };

export type UserPointsQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserPointsQuery = { __typename?: 'Query', UserPoints?: { __typename?: 'UserPointsResult', communityQuest?: number | null, developerQuest?: number | null, expectedReferral?: number | null, partnerQuest?: number | null, referral?: number | null, total?: number | null, viewShortListed?: number | null, votingSessionParticipated?: number | null, winnerAsDesigner?: number | null, winnerAsVoter?: number | null, earlyBonus?: number | null, questPointsSubType?: Array<{ __typename?: 'QuestPointsSubType', points?: number | null, type?: DeveloperQuestSubType | null } | null> | null } | null };

export type LeaderBoardQueryVariables = Exact<{ [key: string]: never; }>;


export type LeaderBoardQuery = { __typename?: 'Query', leaderBoard?: Array<{ __typename?: 'leaderBoardType', rank?: any | null, name?: string | null, points?: any | null } | null> | null };

export type VotingSessionPointsQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type VotingSessionPointsQuery = { __typename?: 'Query', votingSessionPoints?: { __typename?: 'VotingSessionPointsResult', winsAsVoter?: number | null, totalPoints?: number | null, sessionPoints?: Array<{ __typename?: 'sessionPointsType', wonAsDataDesigner?: boolean | null, sessionId?: number | null, points?: number | null } | null> | null } | null };

export type CompletedDevQuestCountQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type CompletedDevQuestCountQuery = { __typename?: 'Query', completedDevQuestCount?: Array<{ __typename?: 'completedDevQuestCountType', count?: number | null, type?: DeveloperQuestSubType | null } | null> | null };

export type FetchGalaxyQuestPointsQueryVariables = Exact<{
  evmWalletAddress: Scalars['String']['input'];
  questType: GalxeQuestType;
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type FetchGalaxyQuestPointsQuery = { __typename?: 'Query', fetchGalaxyQuestPoints?: { __typename?: 'checkGalaxyQuestCompletedType', canClaimMore?: boolean | null, claimedPointsFromGalaxe?: number | null, completed?: boolean | null } | null };

export type ViewSqlQueryVariables = Exact<{
  hashId?: InputMaybe<Scalars['String']['input']>;
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ViewSqlQuery = { __typename?: 'Query', viewSql?: any | null };

export type ViewDataQueryVariables = Exact<{
  hashId?: InputMaybe<Scalars['String']['input']>;
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ViewDataQuery = { __typename?: 'Query', viewData?: any | null };

export type ValidatorLeaderBoardQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidatorLeaderBoardQuery = { __typename?: 'Query', validatorLeaderBoard?: Array<{ __typename?: 'validatorLeaderBoardType', name?: string | null, faults?: string | null, address?: string | null, inclusion?: string | null, discovered?: string | null, rank?: string | null, status?: string | null, valid?: boolean | null, inValidReason?: string | null, score?: string | null, commission?: string | null, rewards?: string | null } | null> | null };

export type EarlyBonusPointsQueryVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type EarlyBonusPointsQuery = { __typename?: 'Query', earlyBonusPoints?: number | null };

export type VotingSessionEndedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type VotingSessionEndedSubscription = { __typename?: 'Subscription', votingSessionEnded?: { __typename?: 'votingSessionSubscriptionType', votingSession?: { __typename?: 'VotingSession', id: number } | null } | null };

export type VotingSessionStartedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type VotingSessionStartedSubscription = { __typename?: 'Subscription', votingSessionStarted?: { __typename?: 'votingSessionSubscriptionType', votingSession?: { __typename?: 'VotingSession', id: number } | null } | null };

export type VotingSessionUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type VotingSessionUpdatedSubscription = { __typename?: 'Subscription', votingSessionUpdated?: { __typename?: 'votingSessionSubscriptionType', votingSession?: { __typename?: 'VotingSession', id: number } | null } | null };

export type VotingSessionPointsDistributedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type VotingSessionPointsDistributedSubscription = { __typename?: 'Subscription', votingSessionPointsDistributed?: { __typename?: 'votingSessionSubscriptionType', votingSession?: { __typename?: 'VotingSession', id: number } | null } | null };


export const ShortListViewsDocument = gql`
    mutation ShortListViews($view: Json!, $accessToken: String) {
  shortListViews(view: $view, accessToken: $accessToken)
}
    `;
export type ShortListViewsMutationFn = Apollo.MutationFunction<ShortListViewsMutation, ShortListViewsMutationVariables>;

/**
 * __useShortListViewsMutation__
 *
 * To run a mutation, you first call `useShortListViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShortListViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shortListViewsMutation, { data, loading, error }] = useShortListViewsMutation({
 *   variables: {
 *      view: // value for 'view'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useShortListViewsMutation(baseOptions?: Apollo.MutationHookOptions<ShortListViewsMutation, ShortListViewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShortListViewsMutation, ShortListViewsMutationVariables>(ShortListViewsDocument, options);
      }
export type ShortListViewsMutationHookResult = ReturnType<typeof useShortListViewsMutation>;
export type ShortListViewsMutationResult = Apollo.MutationResult<ShortListViewsMutation>;
export type ShortListViewsMutationOptions = Apollo.BaseMutationOptions<ShortListViewsMutation, ShortListViewsMutationVariables>;
export const StartVotingSessionDocument = gql`
    mutation StartVotingSession($data: VotingSessionInputType!, $accessToken: String) {
  startVotingSession(data: $data, accessToken: $accessToken)
}
    `;
export type StartVotingSessionMutationFn = Apollo.MutationFunction<StartVotingSessionMutation, StartVotingSessionMutationVariables>;

/**
 * __useStartVotingSessionMutation__
 *
 * To run a mutation, you first call `useStartVotingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartVotingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startVotingSessionMutation, { data, loading, error }] = useStartVotingSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useStartVotingSessionMutation(baseOptions?: Apollo.MutationHookOptions<StartVotingSessionMutation, StartVotingSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartVotingSessionMutation, StartVotingSessionMutationVariables>(StartVotingSessionDocument, options);
      }
export type StartVotingSessionMutationHookResult = ReturnType<typeof useStartVotingSessionMutation>;
export type StartVotingSessionMutationResult = Apollo.MutationResult<StartVotingSessionMutation>;
export type StartVotingSessionMutationOptions = Apollo.BaseMutationOptions<StartVotingSessionMutation, StartVotingSessionMutationVariables>;
export const VoteDocument = gql`
    mutation Vote($data: VoteInputArgs!, $accessToken: String) {
  vote(data: $data, accessToken: $accessToken)
}
    `;
export type VoteMutationFn = Apollo.MutationFunction<VoteMutation, VoteMutationVariables>;

/**
 * __useVoteMutation__
 *
 * To run a mutation, you first call `useVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteMutation, { data, loading, error }] = useVoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useVoteMutation(baseOptions?: Apollo.MutationHookOptions<VoteMutation, VoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoteMutation, VoteMutationVariables>(VoteDocument, options);
      }
export type VoteMutationHookResult = ReturnType<typeof useVoteMutation>;
export type VoteMutationResult = Apollo.MutationResult<VoteMutation>;
export type VoteMutationOptions = Apollo.BaseMutationOptions<VoteMutation, VoteMutationVariables>;
export const EndVotingSessionDocument = gql`
    mutation EndVotingSession($accessToken: String) {
  endVotingSession(accessToken: $accessToken)
}
    `;
export type EndVotingSessionMutationFn = Apollo.MutationFunction<EndVotingSessionMutation, EndVotingSessionMutationVariables>;

/**
 * __useEndVotingSessionMutation__
 *
 * To run a mutation, you first call `useEndVotingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndVotingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endVotingSessionMutation, { data, loading, error }] = useEndVotingSessionMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useEndVotingSessionMutation(baseOptions?: Apollo.MutationHookOptions<EndVotingSessionMutation, EndVotingSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndVotingSessionMutation, EndVotingSessionMutationVariables>(EndVotingSessionDocument, options);
      }
export type EndVotingSessionMutationHookResult = ReturnType<typeof useEndVotingSessionMutation>;
export type EndVotingSessionMutationResult = Apollo.MutationResult<EndVotingSessionMutation>;
export type EndVotingSessionMutationOptions = Apollo.BaseMutationOptions<EndVotingSessionMutation, EndVotingSessionMutationVariables>;
export const UpdateVotingSessionDocument = gql`
    mutation UpdateVotingSession($updateVotingSessionId: Int!, $name: String, $endDate: String, $accessToken: String) {
  updateVotingSession(
    id: $updateVotingSessionId
    name: $name
    endDate: $endDate
    accessToken: $accessToken
  )
}
    `;
export type UpdateVotingSessionMutationFn = Apollo.MutationFunction<UpdateVotingSessionMutation, UpdateVotingSessionMutationVariables>;

/**
 * __useUpdateVotingSessionMutation__
 *
 * To run a mutation, you first call `useUpdateVotingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVotingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVotingSessionMutation, { data, loading, error }] = useUpdateVotingSessionMutation({
 *   variables: {
 *      updateVotingSessionId: // value for 'updateVotingSessionId'
 *      name: // value for 'name'
 *      endDate: // value for 'endDate'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useUpdateVotingSessionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVotingSessionMutation, UpdateVotingSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVotingSessionMutation, UpdateVotingSessionMutationVariables>(UpdateVotingSessionDocument, options);
      }
export type UpdateVotingSessionMutationHookResult = ReturnType<typeof useUpdateVotingSessionMutation>;
export type UpdateVotingSessionMutationResult = Apollo.MutationResult<UpdateVotingSessionMutation>;
export type UpdateVotingSessionMutationOptions = Apollo.BaseMutationOptions<UpdateVotingSessionMutation, UpdateVotingSessionMutationVariables>;
export const ConnectWalletDocument = gql`
    mutation ConnectWallet($walletAddress: String!, $accessToken: String) {
  connectWallet(walletAddress: $walletAddress, accessToken: $accessToken)
}
    `;
export type ConnectWalletMutationFn = Apollo.MutationFunction<ConnectWalletMutation, ConnectWalletMutationVariables>;

/**
 * __useConnectWalletMutation__
 *
 * To run a mutation, you first call `useConnectWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectWalletMutation, { data, loading, error }] = useConnectWalletMutation({
 *   variables: {
 *      walletAddress: // value for 'walletAddress'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useConnectWalletMutation(baseOptions?: Apollo.MutationHookOptions<ConnectWalletMutation, ConnectWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectWalletMutation, ConnectWalletMutationVariables>(ConnectWalletDocument, options);
      }
export type ConnectWalletMutationHookResult = ReturnType<typeof useConnectWalletMutation>;
export type ConnectWalletMutationResult = Apollo.MutationResult<ConnectWalletMutation>;
export type ConnectWalletMutationOptions = Apollo.BaseMutationOptions<ConnectWalletMutation, ConnectWalletMutationVariables>;
export const SwitchSubstrateWalletDocument = gql`
    mutation SwitchSubstrateWallet($newWalletAddress: String!, $accessToken: String) {
  switchSubstrateWallet(
    newWalletAddress: $newWalletAddress
    accessToken: $accessToken
  )
}
    `;
export type SwitchSubstrateWalletMutationFn = Apollo.MutationFunction<SwitchSubstrateWalletMutation, SwitchSubstrateWalletMutationVariables>;

/**
 * __useSwitchSubstrateWalletMutation__
 *
 * To run a mutation, you first call `useSwitchSubstrateWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchSubstrateWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchSubstrateWalletMutation, { data, loading, error }] = useSwitchSubstrateWalletMutation({
 *   variables: {
 *      newWalletAddress: // value for 'newWalletAddress'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSwitchSubstrateWalletMutation(baseOptions?: Apollo.MutationHookOptions<SwitchSubstrateWalletMutation, SwitchSubstrateWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchSubstrateWalletMutation, SwitchSubstrateWalletMutationVariables>(SwitchSubstrateWalletDocument, options);
      }
export type SwitchSubstrateWalletMutationHookResult = ReturnType<typeof useSwitchSubstrateWalletMutation>;
export type SwitchSubstrateWalletMutationResult = Apollo.MutationResult<SwitchSubstrateWalletMutation>;
export type SwitchSubstrateWalletMutationOptions = Apollo.BaseMutationOptions<SwitchSubstrateWalletMutation, SwitchSubstrateWalletMutationVariables>;
export const ConnectedEvmWalletQuestDocument = gql`
    mutation ConnectedEvmWalletQuest($walletAddress: String!, $accessToken: String) {
  connectedEvmWalletQuest(
    walletAddress: $walletAddress
    accessToken: $accessToken
  )
}
    `;
export type ConnectedEvmWalletQuestMutationFn = Apollo.MutationFunction<ConnectedEvmWalletQuestMutation, ConnectedEvmWalletQuestMutationVariables>;

/**
 * __useConnectedEvmWalletQuestMutation__
 *
 * To run a mutation, you first call `useConnectedEvmWalletQuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectedEvmWalletQuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectedEvmWalletQuestMutation, { data, loading, error }] = useConnectedEvmWalletQuestMutation({
 *   variables: {
 *      walletAddress: // value for 'walletAddress'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useConnectedEvmWalletQuestMutation(baseOptions?: Apollo.MutationHookOptions<ConnectedEvmWalletQuestMutation, ConnectedEvmWalletQuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectedEvmWalletQuestMutation, ConnectedEvmWalletQuestMutationVariables>(ConnectedEvmWalletQuestDocument, options);
      }
export type ConnectedEvmWalletQuestMutationHookResult = ReturnType<typeof useConnectedEvmWalletQuestMutation>;
export type ConnectedEvmWalletQuestMutationResult = Apollo.MutationResult<ConnectedEvmWalletQuestMutation>;
export type ConnectedEvmWalletQuestMutationOptions = Apollo.BaseMutationOptions<ConnectedEvmWalletQuestMutation, ConnectedEvmWalletQuestMutationVariables>;
export const GmpSubmitMessageQuestDocument = gql`
    mutation GMPSubmitMessageQuest($evmWalletAddress: String!, $transactionHash: String!, $connectedChain: connectedChainType!, $accessToken: String) {
  GMPSubmitMessageQuest(
    evmWalletAddress: $evmWalletAddress
    transactionHash: $transactionHash
    connectedChain: $connectedChain
    accessToken: $accessToken
  )
}
    `;
export type GmpSubmitMessageQuestMutationFn = Apollo.MutationFunction<GmpSubmitMessageQuestMutation, GmpSubmitMessageQuestMutationVariables>;

/**
 * __useGmpSubmitMessageQuestMutation__
 *
 * To run a mutation, you first call `useGmpSubmitMessageQuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGmpSubmitMessageQuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gmpSubmitMessageQuestMutation, { data, loading, error }] = useGmpSubmitMessageQuestMutation({
 *   variables: {
 *      evmWalletAddress: // value for 'evmWalletAddress'
 *      transactionHash: // value for 'transactionHash'
 *      connectedChain: // value for 'connectedChain'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useGmpSubmitMessageQuestMutation(baseOptions?: Apollo.MutationHookOptions<GmpSubmitMessageQuestMutation, GmpSubmitMessageQuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GmpSubmitMessageQuestMutation, GmpSubmitMessageQuestMutationVariables>(GmpSubmitMessageQuestDocument, options);
      }
export type GmpSubmitMessageQuestMutationHookResult = ReturnType<typeof useGmpSubmitMessageQuestMutation>;
export type GmpSubmitMessageQuestMutationResult = Apollo.MutationResult<GmpSubmitMessageQuestMutation>;
export type GmpSubmitMessageQuestMutationOptions = Apollo.BaseMutationOptions<GmpSubmitMessageQuestMutation, GmpSubmitMessageQuestMutationVariables>;
export const GmpContractDeployQuestDocument = gql`
    mutation GMPContractDeployQuest($evmWalletAddress: String!, $contractAddress: String!, $connectedChain: connectedChainType!, $accessToken: String) {
  GMPContractDeployQuest(
    evmWalletAddress: $evmWalletAddress
    contractAddress: $contractAddress
    connectedChain: $connectedChain
    accessToken: $accessToken
  )
}
    `;
export type GmpContractDeployQuestMutationFn = Apollo.MutationFunction<GmpContractDeployQuestMutation, GmpContractDeployQuestMutationVariables>;

/**
 * __useGmpContractDeployQuestMutation__
 *
 * To run a mutation, you first call `useGmpContractDeployQuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGmpContractDeployQuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gmpContractDeployQuestMutation, { data, loading, error }] = useGmpContractDeployQuestMutation({
 *   variables: {
 *      evmWalletAddress: // value for 'evmWalletAddress'
 *      contractAddress: // value for 'contractAddress'
 *      connectedChain: // value for 'connectedChain'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useGmpContractDeployQuestMutation(baseOptions?: Apollo.MutationHookOptions<GmpContractDeployQuestMutation, GmpContractDeployQuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GmpContractDeployQuestMutation, GmpContractDeployQuestMutationVariables>(GmpContractDeployQuestDocument, options);
      }
export type GmpContractDeployQuestMutationHookResult = ReturnType<typeof useGmpContractDeployQuestMutation>;
export type GmpContractDeployQuestMutationResult = Apollo.MutationResult<GmpContractDeployQuestMutation>;
export type GmpContractDeployQuestMutationOptions = Apollo.BaseMutationOptions<GmpContractDeployQuestMutation, GmpContractDeployQuestMutationVariables>;
export const SubmittedFeedbackQuestDocument = gql`
    mutation SubmittedFeedbackQuest($email: String!) {
  submittedFeedbackQuest(email: $email)
}
    `;
export type SubmittedFeedbackQuestMutationFn = Apollo.MutationFunction<SubmittedFeedbackQuestMutation, SubmittedFeedbackQuestMutationVariables>;

/**
 * __useSubmittedFeedbackQuestMutation__
 *
 * To run a mutation, you first call `useSubmittedFeedbackQuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmittedFeedbackQuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submittedFeedbackQuestMutation, { data, loading, error }] = useSubmittedFeedbackQuestMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSubmittedFeedbackQuestMutation(baseOptions?: Apollo.MutationHookOptions<SubmittedFeedbackQuestMutation, SubmittedFeedbackQuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmittedFeedbackQuestMutation, SubmittedFeedbackQuestMutationVariables>(SubmittedFeedbackQuestDocument, options);
      }
export type SubmittedFeedbackQuestMutationHookResult = ReturnType<typeof useSubmittedFeedbackQuestMutation>;
export type SubmittedFeedbackQuestMutationResult = Apollo.MutationResult<SubmittedFeedbackQuestMutation>;
export type SubmittedFeedbackQuestMutationOptions = Apollo.BaseMutationOptions<SubmittedFeedbackQuestMutation, SubmittedFeedbackQuestMutationVariables>;
export const ClaimGalxePointsDocument = gql`
    mutation ClaimGalxePoints($evmWalletAddress: String!, $questType: GalxeQuestType!, $accessToken: String) {
  ClaimGalxePoints(
    evmWalletAddress: $evmWalletAddress
    questType: $questType
    accessToken: $accessToken
  )
}
    `;
export type ClaimGalxePointsMutationFn = Apollo.MutationFunction<ClaimGalxePointsMutation, ClaimGalxePointsMutationVariables>;

/**
 * __useClaimGalxePointsMutation__
 *
 * To run a mutation, you first call `useClaimGalxePointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimGalxePointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimGalxePointsMutation, { data, loading, error }] = useClaimGalxePointsMutation({
 *   variables: {
 *      evmWalletAddress: // value for 'evmWalletAddress'
 *      questType: // value for 'questType'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useClaimGalxePointsMutation(baseOptions?: Apollo.MutationHookOptions<ClaimGalxePointsMutation, ClaimGalxePointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimGalxePointsMutation, ClaimGalxePointsMutationVariables>(ClaimGalxePointsDocument, options);
      }
export type ClaimGalxePointsMutationHookResult = ReturnType<typeof useClaimGalxePointsMutation>;
export type ClaimGalxePointsMutationResult = Apollo.MutationResult<ClaimGalxePointsMutation>;
export type ClaimGalxePointsMutationOptions = Apollo.BaseMutationOptions<ClaimGalxePointsMutation, ClaimGalxePointsMutationVariables>;
export const SetFpDocument = gql`
    mutation SetFP($vId: String!, $accessToken: String) {
  setFP(vId: $vId, accessToken: $accessToken)
}
    `;
export type SetFpMutationFn = Apollo.MutationFunction<SetFpMutation, SetFpMutationVariables>;

/**
 * __useSetFpMutation__
 *
 * To run a mutation, you first call `useSetFpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFpMutation, { data, loading, error }] = useSetFpMutation({
 *   variables: {
 *      vId: // value for 'vId'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSetFpMutation(baseOptions?: Apollo.MutationHookOptions<SetFpMutation, SetFpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFpMutation, SetFpMutationVariables>(SetFpDocument, options);
      }
export type SetFpMutationHookResult = ReturnType<typeof useSetFpMutation>;
export type SetFpMutationResult = Apollo.MutationResult<SetFpMutation>;
export type SetFpMutationOptions = Apollo.BaseMutationOptions<SetFpMutation, SetFpMutationVariables>;
export const ClaimIntractPointDocument = gql`
    mutation ClaimIntractPoint($evmAddress: String!, $accessToken: String) {
  claimIntractPoint(evmAddress: $evmAddress, accessToken: $accessToken) {
    awarded
    eligible
  }
}
    `;
export type ClaimIntractPointMutationFn = Apollo.MutationFunction<ClaimIntractPointMutation, ClaimIntractPointMutationVariables>;

/**
 * __useClaimIntractPointMutation__
 *
 * To run a mutation, you first call `useClaimIntractPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimIntractPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimIntractPointMutation, { data, loading, error }] = useClaimIntractPointMutation({
 *   variables: {
 *      evmAddress: // value for 'evmAddress'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useClaimIntractPointMutation(baseOptions?: Apollo.MutationHookOptions<ClaimIntractPointMutation, ClaimIntractPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimIntractPointMutation, ClaimIntractPointMutationVariables>(ClaimIntractPointDocument, options);
      }
export type ClaimIntractPointMutationHookResult = ReturnType<typeof useClaimIntractPointMutation>;
export type ClaimIntractPointMutationResult = Apollo.MutationResult<ClaimIntractPointMutation>;
export type ClaimIntractPointMutationOptions = Apollo.BaseMutationOptions<ClaimIntractPointMutation, ClaimIntractPointMutationVariables>;
export const VerifyProofOfHumanityDocument = gql`
    mutation VerifyProofOfHumanity($evmWalletAddress: String!, $accessToken: String) {
  verifyProofOfHumanity(
    evmWalletAddress: $evmWalletAddress
    accessToken: $accessToken
  )
}
    `;
export type VerifyProofOfHumanityMutationFn = Apollo.MutationFunction<VerifyProofOfHumanityMutation, VerifyProofOfHumanityMutationVariables>;

/**
 * __useVerifyProofOfHumanityMutation__
 *
 * To run a mutation, you first call `useVerifyProofOfHumanityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyProofOfHumanityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyProofOfHumanityMutation, { data, loading, error }] = useVerifyProofOfHumanityMutation({
 *   variables: {
 *      evmWalletAddress: // value for 'evmWalletAddress'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useVerifyProofOfHumanityMutation(baseOptions?: Apollo.MutationHookOptions<VerifyProofOfHumanityMutation, VerifyProofOfHumanityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyProofOfHumanityMutation, VerifyProofOfHumanityMutationVariables>(VerifyProofOfHumanityDocument, options);
      }
export type VerifyProofOfHumanityMutationHookResult = ReturnType<typeof useVerifyProofOfHumanityMutation>;
export type VerifyProofOfHumanityMutationResult = Apollo.MutationResult<VerifyProofOfHumanityMutation>;
export type VerifyProofOfHumanityMutationOptions = Apollo.BaseMutationOptions<VerifyProofOfHumanityMutation, VerifyProofOfHumanityMutationVariables>;
export const VerifyDmailSendEmailQuestDocument = gql`
    mutation VerifyDmailSendEmailQuest($accessToken: String) {
  verifyDmailSendEmailQuest(accessToken: $accessToken)
}
    `;
export type VerifyDmailSendEmailQuestMutationFn = Apollo.MutationFunction<VerifyDmailSendEmailQuestMutation, VerifyDmailSendEmailQuestMutationVariables>;

/**
 * __useVerifyDmailSendEmailQuestMutation__
 *
 * To run a mutation, you first call `useVerifyDmailSendEmailQuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyDmailSendEmailQuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyDmailSendEmailQuestMutation, { data, loading, error }] = useVerifyDmailSendEmailQuestMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useVerifyDmailSendEmailQuestMutation(baseOptions?: Apollo.MutationHookOptions<VerifyDmailSendEmailQuestMutation, VerifyDmailSendEmailQuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyDmailSendEmailQuestMutation, VerifyDmailSendEmailQuestMutationVariables>(VerifyDmailSendEmailQuestDocument, options);
      }
export type VerifyDmailSendEmailQuestMutationHookResult = ReturnType<typeof useVerifyDmailSendEmailQuestMutation>;
export type VerifyDmailSendEmailQuestMutationResult = Apollo.MutationResult<VerifyDmailSendEmailQuestMutation>;
export type VerifyDmailSendEmailQuestMutationOptions = Apollo.BaseMutationOptions<VerifyDmailSendEmailQuestMutation, VerifyDmailSendEmailQuestMutationVariables>;
export const ClaimQuaerePointsDocument = gql`
    mutation ClaimQuaerePoints($accessToken: String) {
  claimQuaerePoints(accessToken: $accessToken)
}
    `;
export type ClaimQuaerePointsMutationFn = Apollo.MutationFunction<ClaimQuaerePointsMutation, ClaimQuaerePointsMutationVariables>;

/**
 * __useClaimQuaerePointsMutation__
 *
 * To run a mutation, you first call `useClaimQuaerePointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimQuaerePointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimQuaerePointsMutation, { data, loading, error }] = useClaimQuaerePointsMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useClaimQuaerePointsMutation(baseOptions?: Apollo.MutationHookOptions<ClaimQuaerePointsMutation, ClaimQuaerePointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimQuaerePointsMutation, ClaimQuaerePointsMutationVariables>(ClaimQuaerePointsDocument, options);
      }
export type ClaimQuaerePointsMutationHookResult = ReturnType<typeof useClaimQuaerePointsMutation>;
export type ClaimQuaerePointsMutationResult = Apollo.MutationResult<ClaimQuaerePointsMutation>;
export type ClaimQuaerePointsMutationOptions = Apollo.BaseMutationOptions<ClaimQuaerePointsMutation, ClaimQuaerePointsMutationVariables>;
export const ClaimZealyQuestPointsDocument = gql`
    mutation ClaimZealyQuestPoints($accessToken: String) {
  claimZealyQuestPoints(accessToken: $accessToken)
}
    `;
export type ClaimZealyQuestPointsMutationFn = Apollo.MutationFunction<ClaimZealyQuestPointsMutation, ClaimZealyQuestPointsMutationVariables>;

/**
 * __useClaimZealyQuestPointsMutation__
 *
 * To run a mutation, you first call `useClaimZealyQuestPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimZealyQuestPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimZealyQuestPointsMutation, { data, loading, error }] = useClaimZealyQuestPointsMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useClaimZealyQuestPointsMutation(baseOptions?: Apollo.MutationHookOptions<ClaimZealyQuestPointsMutation, ClaimZealyQuestPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimZealyQuestPointsMutation, ClaimZealyQuestPointsMutationVariables>(ClaimZealyQuestPointsDocument, options);
      }
export type ClaimZealyQuestPointsMutationHookResult = ReturnType<typeof useClaimZealyQuestPointsMutation>;
export type ClaimZealyQuestPointsMutationResult = Apollo.MutationResult<ClaimZealyQuestPointsMutation>;
export type ClaimZealyQuestPointsMutationOptions = Apollo.BaseMutationOptions<ClaimZealyQuestPointsMutation, ClaimZealyQuestPointsMutationVariables>;
export const VerifyCredentialDocument = gql`
    query VerifyCredential($accessToken: String, $referredCode: String, $referrerSite: String, $Dmailusercode: String) {
  verifyCredential(
    accessToken: $accessToken
    referredCode: $referredCode
    referrerSite: $referrerSite
    Dmailusercode: $Dmailusercode
  )
}
    `;

/**
 * __useVerifyCredentialQuery__
 *
 * To run a query within a React component, call `useVerifyCredentialQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyCredentialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyCredentialQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      referredCode: // value for 'referredCode'
 *      referrerSite: // value for 'referrerSite'
 *      Dmailusercode: // value for 'Dmailusercode'
 *   },
 * });
 */
export function useVerifyCredentialQuery(baseOptions?: Apollo.QueryHookOptions<VerifyCredentialQuery, VerifyCredentialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyCredentialQuery, VerifyCredentialQueryVariables>(VerifyCredentialDocument, options);
      }
export function useVerifyCredentialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyCredentialQuery, VerifyCredentialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyCredentialQuery, VerifyCredentialQueryVariables>(VerifyCredentialDocument, options);
        }
export type VerifyCredentialQueryHookResult = ReturnType<typeof useVerifyCredentialQuery>;
export type VerifyCredentialLazyQueryHookResult = ReturnType<typeof useVerifyCredentialLazyQuery>;
export type VerifyCredentialQueryResult = Apollo.QueryResult<VerifyCredentialQuery, VerifyCredentialQueryVariables>;
export const ActiveVotingSessionDocument = gql`
    query ActiveVotingSession {
  activeVotingSession {
    id
    name
    startDate
    endDate
    created_at
    _count {
      views
      votes
    }
  }
}
    `;

/**
 * __useActiveVotingSessionQuery__
 *
 * To run a query within a React component, call `useActiveVotingSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveVotingSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveVotingSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveVotingSessionQuery(baseOptions?: Apollo.QueryHookOptions<ActiveVotingSessionQuery, ActiveVotingSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveVotingSessionQuery, ActiveVotingSessionQueryVariables>(ActiveVotingSessionDocument, options);
      }
export function useActiveVotingSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveVotingSessionQuery, ActiveVotingSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveVotingSessionQuery, ActiveVotingSessionQueryVariables>(ActiveVotingSessionDocument, options);
        }
export type ActiveVotingSessionQueryHookResult = ReturnType<typeof useActiveVotingSessionQuery>;
export type ActiveVotingSessionLazyQueryHookResult = ReturnType<typeof useActiveVotingSessionLazyQuery>;
export type ActiveVotingSessionQueryResult = Apollo.QueryResult<ActiveVotingSessionQuery, ActiveVotingSessionQueryVariables>;
export const ActiveVotingSessionViewsDocument = gql`
    query ActiveVotingSessionViews($accessToken: String) {
  activeVotingSessionViews(accessToken: $accessToken) {
    hashId
    isWinner
    name
    tags
    created_at
    description
    owner {
      walletAddress
    }
    useCases
  }
}
    `;

/**
 * __useActiveVotingSessionViewsQuery__
 *
 * To run a query within a React component, call `useActiveVotingSessionViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveVotingSessionViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveVotingSessionViewsQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useActiveVotingSessionViewsQuery(baseOptions?: Apollo.QueryHookOptions<ActiveVotingSessionViewsQuery, ActiveVotingSessionViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveVotingSessionViewsQuery, ActiveVotingSessionViewsQueryVariables>(ActiveVotingSessionViewsDocument, options);
      }
export function useActiveVotingSessionViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveVotingSessionViewsQuery, ActiveVotingSessionViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveVotingSessionViewsQuery, ActiveVotingSessionViewsQueryVariables>(ActiveVotingSessionViewsDocument, options);
        }
export type ActiveVotingSessionViewsQueryHookResult = ReturnType<typeof useActiveVotingSessionViewsQuery>;
export type ActiveVotingSessionViewsLazyQueryHookResult = ReturnType<typeof useActiveVotingSessionViewsLazyQuery>;
export type ActiveVotingSessionViewsQueryResult = Apollo.QueryResult<ActiveVotingSessionViewsQuery, ActiveVotingSessionViewsQueryVariables>;
export const VotingSessionListDocument = gql`
    query VotingSessionList($accessToken: String) {
  votingSessionList(accessToken: $accessToken) {
    id
    name
    created_at
    winnerView {
      name
      hashId
    }
    _count {
      votes
      views
    }
  }
}
    `;

/**
 * __useVotingSessionListQuery__
 *
 * To run a query within a React component, call `useVotingSessionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useVotingSessionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVotingSessionListQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useVotingSessionListQuery(baseOptions?: Apollo.QueryHookOptions<VotingSessionListQuery, VotingSessionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VotingSessionListQuery, VotingSessionListQueryVariables>(VotingSessionListDocument, options);
      }
export function useVotingSessionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VotingSessionListQuery, VotingSessionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VotingSessionListQuery, VotingSessionListQueryVariables>(VotingSessionListDocument, options);
        }
export type VotingSessionListQueryHookResult = ReturnType<typeof useVotingSessionListQuery>;
export type VotingSessionListLazyQueryHookResult = ReturnType<typeof useVotingSessionListLazyQuery>;
export type VotingSessionListQueryResult = Apollo.QueryResult<VotingSessionListQuery, VotingSessionListQueryVariables>;
export const SessionWinnerViewDocument = gql`
    query SessionWinnerView($sessionId: Int!, $accessToken: String) {
  SessionWinnerView(sessionId: $sessionId, accessToken: $accessToken) {
    hashId
    description
    name
    tags
    owner {
      walletAddress
    }
    created_at
  }
}
    `;

/**
 * __useSessionWinnerViewQuery__
 *
 * To run a query within a React component, call `useSessionWinnerViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionWinnerViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionWinnerViewQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSessionWinnerViewQuery(baseOptions: Apollo.QueryHookOptions<SessionWinnerViewQuery, SessionWinnerViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionWinnerViewQuery, SessionWinnerViewQueryVariables>(SessionWinnerViewDocument, options);
      }
export function useSessionWinnerViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionWinnerViewQuery, SessionWinnerViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionWinnerViewQuery, SessionWinnerViewQueryVariables>(SessionWinnerViewDocument, options);
        }
export type SessionWinnerViewQueryHookResult = ReturnType<typeof useSessionWinnerViewQuery>;
export type SessionWinnerViewLazyQueryHookResult = ReturnType<typeof useSessionWinnerViewLazyQuery>;
export type SessionWinnerViewQueryResult = Apollo.QueryResult<SessionWinnerViewQuery, SessionWinnerViewQueryVariables>;
export const WatchViewListDocument = gql`
    query WatchViewList($accessToken: String, $searchTerm: String, $page: Int, $limit: Int) {
  watchViewList(
    accessToken: $accessToken
    searchTerm: $searchTerm
    page: $page
    limit: $limit
  )
}
    `;

/**
 * __useWatchViewListQuery__
 *
 * To run a query within a React component, call `useWatchViewListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchViewListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchViewListQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      searchTerm: // value for 'searchTerm'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useWatchViewListQuery(baseOptions?: Apollo.QueryHookOptions<WatchViewListQuery, WatchViewListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WatchViewListQuery, WatchViewListQueryVariables>(WatchViewListDocument, options);
      }
export function useWatchViewListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WatchViewListQuery, WatchViewListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WatchViewListQuery, WatchViewListQueryVariables>(WatchViewListDocument, options);
        }
export type WatchViewListQueryHookResult = ReturnType<typeof useWatchViewListQuery>;
export type WatchViewListLazyQueryHookResult = ReturnType<typeof useWatchViewListLazyQuery>;
export type WatchViewListQueryResult = Apollo.QueryResult<WatchViewListQuery, WatchViewListQueryVariables>;
export const ShortListedViewsDocument = gql`
    query ShortListedViews($accessToken: String) {
  shortListedViews(accessToken: $accessToken) {
    ... on watchView {
      data
    }
    ... on Views {
      description
      hashId
      isBlocked
      name
      owner {
        walletAddress
      }
      tags
    }
  }
}
    `;

/**
 * __useShortListedViewsQuery__
 *
 * To run a query within a React component, call `useShortListedViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortListedViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortListedViewsQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useShortListedViewsQuery(baseOptions?: Apollo.QueryHookOptions<ShortListedViewsQuery, ShortListedViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortListedViewsQuery, ShortListedViewsQueryVariables>(ShortListedViewsDocument, options);
      }
export function useShortListedViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortListedViewsQuery, ShortListedViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortListedViewsQuery, ShortListedViewsQueryVariables>(ShortListedViewsDocument, options);
        }
export type ShortListedViewsQueryHookResult = ReturnType<typeof useShortListedViewsQuery>;
export type ShortListedViewsLazyQueryHookResult = ReturnType<typeof useShortListedViewsLazyQuery>;
export type ShortListedViewsQueryResult = Apollo.QueryResult<ShortListedViewsQuery, ShortListedViewsQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($accessToken: String) {
  getUser(accessToken: $accessToken) {
    id
    name
    created_at
    email
    walletAddress
    referralCode
    connectedDiscordServer
    isVerifiedByPOH
    POHScore
    rank
    _count {
      referredToUsers
    }
    votingSessionParticipated {
      votingSessionId
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const UserPointsDocument = gql`
    query UserPoints($accessToken: String) {
  UserPoints(accessToken: $accessToken) {
    communityQuest
    developerQuest
    expectedReferral
    partnerQuest
    questPointsSubType {
      points
      type
    }
    referral
    total
    viewShortListed
    votingSessionParticipated
    winnerAsDesigner
    winnerAsVoter
    earlyBonus
  }
}
    `;

/**
 * __useUserPointsQuery__
 *
 * To run a query within a React component, call `useUserPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPointsQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useUserPointsQuery(baseOptions?: Apollo.QueryHookOptions<UserPointsQuery, UserPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPointsQuery, UserPointsQueryVariables>(UserPointsDocument, options);
      }
export function useUserPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPointsQuery, UserPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPointsQuery, UserPointsQueryVariables>(UserPointsDocument, options);
        }
export type UserPointsQueryHookResult = ReturnType<typeof useUserPointsQuery>;
export type UserPointsLazyQueryHookResult = ReturnType<typeof useUserPointsLazyQuery>;
export type UserPointsQueryResult = Apollo.QueryResult<UserPointsQuery, UserPointsQueryVariables>;
export const LeaderBoardDocument = gql`
    query LeaderBoard {
  leaderBoard {
    rank
    name
    points
  }
}
    `;

/**
 * __useLeaderBoardQuery__
 *
 * To run a query within a React component, call `useLeaderBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaderBoardQuery({
 *   variables: {
 *   },
 * });
 */
export function useLeaderBoardQuery(baseOptions?: Apollo.QueryHookOptions<LeaderBoardQuery, LeaderBoardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeaderBoardQuery, LeaderBoardQueryVariables>(LeaderBoardDocument, options);
      }
export function useLeaderBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeaderBoardQuery, LeaderBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeaderBoardQuery, LeaderBoardQueryVariables>(LeaderBoardDocument, options);
        }
export type LeaderBoardQueryHookResult = ReturnType<typeof useLeaderBoardQuery>;
export type LeaderBoardLazyQueryHookResult = ReturnType<typeof useLeaderBoardLazyQuery>;
export type LeaderBoardQueryResult = Apollo.QueryResult<LeaderBoardQuery, LeaderBoardQueryVariables>;
export const VotingSessionPointsDocument = gql`
    query VotingSessionPoints($accessToken: String) {
  votingSessionPoints(accessToken: $accessToken) {
    winsAsVoter
    totalPoints
    sessionPoints {
      wonAsDataDesigner
      sessionId
      points
    }
  }
}
    `;

/**
 * __useVotingSessionPointsQuery__
 *
 * To run a query within a React component, call `useVotingSessionPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVotingSessionPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVotingSessionPointsQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useVotingSessionPointsQuery(baseOptions?: Apollo.QueryHookOptions<VotingSessionPointsQuery, VotingSessionPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VotingSessionPointsQuery, VotingSessionPointsQueryVariables>(VotingSessionPointsDocument, options);
      }
export function useVotingSessionPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VotingSessionPointsQuery, VotingSessionPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VotingSessionPointsQuery, VotingSessionPointsQueryVariables>(VotingSessionPointsDocument, options);
        }
export type VotingSessionPointsQueryHookResult = ReturnType<typeof useVotingSessionPointsQuery>;
export type VotingSessionPointsLazyQueryHookResult = ReturnType<typeof useVotingSessionPointsLazyQuery>;
export type VotingSessionPointsQueryResult = Apollo.QueryResult<VotingSessionPointsQuery, VotingSessionPointsQueryVariables>;
export const CompletedDevQuestCountDocument = gql`
    query CompletedDevQuestCount($accessToken: String) {
  completedDevQuestCount(accessToken: $accessToken) {
    count
    type
  }
}
    `;

/**
 * __useCompletedDevQuestCountQuery__
 *
 * To run a query within a React component, call `useCompletedDevQuestCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedDevQuestCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedDevQuestCountQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useCompletedDevQuestCountQuery(baseOptions?: Apollo.QueryHookOptions<CompletedDevQuestCountQuery, CompletedDevQuestCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompletedDevQuestCountQuery, CompletedDevQuestCountQueryVariables>(CompletedDevQuestCountDocument, options);
      }
export function useCompletedDevQuestCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedDevQuestCountQuery, CompletedDevQuestCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompletedDevQuestCountQuery, CompletedDevQuestCountQueryVariables>(CompletedDevQuestCountDocument, options);
        }
export type CompletedDevQuestCountQueryHookResult = ReturnType<typeof useCompletedDevQuestCountQuery>;
export type CompletedDevQuestCountLazyQueryHookResult = ReturnType<typeof useCompletedDevQuestCountLazyQuery>;
export type CompletedDevQuestCountQueryResult = Apollo.QueryResult<CompletedDevQuestCountQuery, CompletedDevQuestCountQueryVariables>;
export const FetchGalaxyQuestPointsDocument = gql`
    query FetchGalaxyQuestPoints($evmWalletAddress: String!, $questType: GalxeQuestType!, $accessToken: String) {
  fetchGalaxyQuestPoints(
    evmWalletAddress: $evmWalletAddress
    questType: $questType
    accessToken: $accessToken
  ) {
    canClaimMore
    claimedPointsFromGalaxe
    completed
  }
}
    `;

/**
 * __useFetchGalaxyQuestPointsQuery__
 *
 * To run a query within a React component, call `useFetchGalaxyQuestPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchGalaxyQuestPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGalaxyQuestPointsQuery({
 *   variables: {
 *      evmWalletAddress: // value for 'evmWalletAddress'
 *      questType: // value for 'questType'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useFetchGalaxyQuestPointsQuery(baseOptions: Apollo.QueryHookOptions<FetchGalaxyQuestPointsQuery, FetchGalaxyQuestPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchGalaxyQuestPointsQuery, FetchGalaxyQuestPointsQueryVariables>(FetchGalaxyQuestPointsDocument, options);
      }
export function useFetchGalaxyQuestPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchGalaxyQuestPointsQuery, FetchGalaxyQuestPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchGalaxyQuestPointsQuery, FetchGalaxyQuestPointsQueryVariables>(FetchGalaxyQuestPointsDocument, options);
        }
export type FetchGalaxyQuestPointsQueryHookResult = ReturnType<typeof useFetchGalaxyQuestPointsQuery>;
export type FetchGalaxyQuestPointsLazyQueryHookResult = ReturnType<typeof useFetchGalaxyQuestPointsLazyQuery>;
export type FetchGalaxyQuestPointsQueryResult = Apollo.QueryResult<FetchGalaxyQuestPointsQuery, FetchGalaxyQuestPointsQueryVariables>;
export const ViewSqlDocument = gql`
    query ViewSql($hashId: String, $accessToken: String) {
  viewSql(hashId: $hashId, accessToken: $accessToken)
}
    `;

/**
 * __useViewSqlQuery__
 *
 * To run a query within a React component, call `useViewSqlQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewSqlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewSqlQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useViewSqlQuery(baseOptions?: Apollo.QueryHookOptions<ViewSqlQuery, ViewSqlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewSqlQuery, ViewSqlQueryVariables>(ViewSqlDocument, options);
      }
export function useViewSqlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewSqlQuery, ViewSqlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewSqlQuery, ViewSqlQueryVariables>(ViewSqlDocument, options);
        }
export type ViewSqlQueryHookResult = ReturnType<typeof useViewSqlQuery>;
export type ViewSqlLazyQueryHookResult = ReturnType<typeof useViewSqlLazyQuery>;
export type ViewSqlQueryResult = Apollo.QueryResult<ViewSqlQuery, ViewSqlQueryVariables>;
export const ViewDataDocument = gql`
    query ViewData($hashId: String, $accessToken: String) {
  viewData(hashId: $hashId, accessToken: $accessToken)
}
    `;

/**
 * __useViewDataQuery__
 *
 * To run a query within a React component, call `useViewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDataQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useViewDataQuery(baseOptions?: Apollo.QueryHookOptions<ViewDataQuery, ViewDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewDataQuery, ViewDataQueryVariables>(ViewDataDocument, options);
      }
export function useViewDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewDataQuery, ViewDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewDataQuery, ViewDataQueryVariables>(ViewDataDocument, options);
        }
export type ViewDataQueryHookResult = ReturnType<typeof useViewDataQuery>;
export type ViewDataLazyQueryHookResult = ReturnType<typeof useViewDataLazyQuery>;
export type ViewDataQueryResult = Apollo.QueryResult<ViewDataQuery, ViewDataQueryVariables>;
export const ValidatorLeaderBoardDocument = gql`
    query ValidatorLeaderBoard {
  validatorLeaderBoard {
    name
    faults
    address
    inclusion
    discovered
    rank
    status
    valid
    inValidReason
    score
    commission
    rewards
  }
}
    `;

/**
 * __useValidatorLeaderBoardQuery__
 *
 * To run a query within a React component, call `useValidatorLeaderBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatorLeaderBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatorLeaderBoardQuery({
 *   variables: {
 *   },
 * });
 */
export function useValidatorLeaderBoardQuery(baseOptions?: Apollo.QueryHookOptions<ValidatorLeaderBoardQuery, ValidatorLeaderBoardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidatorLeaderBoardQuery, ValidatorLeaderBoardQueryVariables>(ValidatorLeaderBoardDocument, options);
      }
export function useValidatorLeaderBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidatorLeaderBoardQuery, ValidatorLeaderBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidatorLeaderBoardQuery, ValidatorLeaderBoardQueryVariables>(ValidatorLeaderBoardDocument, options);
        }
export type ValidatorLeaderBoardQueryHookResult = ReturnType<typeof useValidatorLeaderBoardQuery>;
export type ValidatorLeaderBoardLazyQueryHookResult = ReturnType<typeof useValidatorLeaderBoardLazyQuery>;
export type ValidatorLeaderBoardQueryResult = Apollo.QueryResult<ValidatorLeaderBoardQuery, ValidatorLeaderBoardQueryVariables>;
export const EarlyBonusPointsDocument = gql`
    query EarlyBonusPoints($accessToken: String) {
  earlyBonusPoints(accessToken: $accessToken)
}
    `;

/**
 * __useEarlyBonusPointsQuery__
 *
 * To run a query within a React component, call `useEarlyBonusPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarlyBonusPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarlyBonusPointsQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useEarlyBonusPointsQuery(baseOptions?: Apollo.QueryHookOptions<EarlyBonusPointsQuery, EarlyBonusPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EarlyBonusPointsQuery, EarlyBonusPointsQueryVariables>(EarlyBonusPointsDocument, options);
      }
export function useEarlyBonusPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EarlyBonusPointsQuery, EarlyBonusPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EarlyBonusPointsQuery, EarlyBonusPointsQueryVariables>(EarlyBonusPointsDocument, options);
        }
export type EarlyBonusPointsQueryHookResult = ReturnType<typeof useEarlyBonusPointsQuery>;
export type EarlyBonusPointsLazyQueryHookResult = ReturnType<typeof useEarlyBonusPointsLazyQuery>;
export type EarlyBonusPointsQueryResult = Apollo.QueryResult<EarlyBonusPointsQuery, EarlyBonusPointsQueryVariables>;
export const VotingSessionEndedDocument = gql`
    subscription VotingSessionEnded {
  votingSessionEnded {
    votingSession {
      id
    }
  }
}
    `;

/**
 * __useVotingSessionEndedSubscription__
 *
 * To run a query within a React component, call `useVotingSessionEndedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVotingSessionEndedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVotingSessionEndedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVotingSessionEndedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<VotingSessionEndedSubscription, VotingSessionEndedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<VotingSessionEndedSubscription, VotingSessionEndedSubscriptionVariables>(VotingSessionEndedDocument, options);
      }
export type VotingSessionEndedSubscriptionHookResult = ReturnType<typeof useVotingSessionEndedSubscription>;
export type VotingSessionEndedSubscriptionResult = Apollo.SubscriptionResult<VotingSessionEndedSubscription>;
export const VotingSessionStartedDocument = gql`
    subscription VotingSessionStarted {
  votingSessionStarted {
    votingSession {
      id
    }
  }
}
    `;

/**
 * __useVotingSessionStartedSubscription__
 *
 * To run a query within a React component, call `useVotingSessionStartedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVotingSessionStartedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVotingSessionStartedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVotingSessionStartedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<VotingSessionStartedSubscription, VotingSessionStartedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<VotingSessionStartedSubscription, VotingSessionStartedSubscriptionVariables>(VotingSessionStartedDocument, options);
      }
export type VotingSessionStartedSubscriptionHookResult = ReturnType<typeof useVotingSessionStartedSubscription>;
export type VotingSessionStartedSubscriptionResult = Apollo.SubscriptionResult<VotingSessionStartedSubscription>;
export const VotingSessionUpdatedDocument = gql`
    subscription VotingSessionUpdated {
  votingSessionUpdated {
    votingSession {
      id
    }
  }
}
    `;

/**
 * __useVotingSessionUpdatedSubscription__
 *
 * To run a query within a React component, call `useVotingSessionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVotingSessionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVotingSessionUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVotingSessionUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<VotingSessionUpdatedSubscription, VotingSessionUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<VotingSessionUpdatedSubscription, VotingSessionUpdatedSubscriptionVariables>(VotingSessionUpdatedDocument, options);
      }
export type VotingSessionUpdatedSubscriptionHookResult = ReturnType<typeof useVotingSessionUpdatedSubscription>;
export type VotingSessionUpdatedSubscriptionResult = Apollo.SubscriptionResult<VotingSessionUpdatedSubscription>;
export const VotingSessionPointsDistributedDocument = gql`
    subscription VotingSessionPointsDistributed {
  votingSessionPointsDistributed {
    votingSession {
      id
    }
  }
}
    `;

/**
 * __useVotingSessionPointsDistributedSubscription__
 *
 * To run a query within a React component, call `useVotingSessionPointsDistributedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVotingSessionPointsDistributedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVotingSessionPointsDistributedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVotingSessionPointsDistributedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<VotingSessionPointsDistributedSubscription, VotingSessionPointsDistributedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<VotingSessionPointsDistributedSubscription, VotingSessionPointsDistributedSubscriptionVariables>(VotingSessionPointsDistributedDocument, options);
      }
export type VotingSessionPointsDistributedSubscriptionHookResult = ReturnType<typeof useVotingSessionPointsDistributedSubscription>;
export type VotingSessionPointsDistributedSubscriptionResult = Apollo.SubscriptionResult<VotingSessionPointsDistributedSubscription>;