import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export function ReCaptchaProvider({ children }: { children: React.ReactNode }) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={import.meta.env.VITE_WATCH_GOOGLE_CAPTCHA_SITEKEY}
      useEnterprise
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}

