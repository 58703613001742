import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import { AuthProvider, ReCaptchaProvider, WalletProvider } from 'context';
import { SkeletonTheme } from 'react-loading-skeleton';

function App() {
  return (
    <div className="App">
      <SkeletonTheme borderRadius={0} baseColor="#ffffff1f">
        <ReCaptchaProvider>
          <AuthProvider>
            <WalletProvider>
              <RouterProvider router={router} />
            </WalletProvider>
          </AuthProvider>
        </ReCaptchaProvider>
      </SkeletonTheme>
    </div>
  );
}

export default App;
